
(function () {
  $(window).on('scroll', function () {
    if ($(window).scrollTop() > 0) {
      $('.header').addClass('sticky')
    } else {
      $('.header').removeClass('sticky')
    }
  })

  $('.navbar-trigger').on('click', function (e) {
    e.preventDefault();
    $('.side-nav').toggleClass('active')
  })

  $('.sidebar__close').on('click', function (e) {
    e.preventDefault()
    $('.side-nav').removeClass('active')
  })

  $('.side-nav .arrow-more').on('click', function (e) {
    e.preventDefault()
    $(this).toggleClass('active')
    $(this).next().next().toggleClass('active')
  })
  $("a.backTop").on('click', function(e) {
    e.preventDefault()
    $("html, body").animate({ scrollTop: 0 }, "slow");
    return false;
  });
  AOS.init();
  setTimeout(function(){
      jQuery('.lazy-iframe').each(function(){
          jQuery(this).attr('src', jQuery(this).attr('data-src'));
      });
  }, 5000);
})()
